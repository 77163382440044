<template>
  <div id="productsDetails" style="padding-top: 126px">
    <app-header />
    <!-- 位置 -->
    <div class="current_position w">
      <div class="current_position_text">当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          v-for="(item, i) in $route.meta.breadcrumb"
          :to="item.path ? { path: item.path } : ''"
          :key="i"
          >{{ item.name }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <!-- 详情 -->
    <div class="particularsList w">
      <div class="left">
        <img :src="productInfoList.product_img" alt="" />
      </div>
      <div class="right">
        <div class="th">{{ productInfoList.product_name }}</div>
        <div class="tb" style="color: #ffa71d">{{ productInfoList.price }}</div>
        <div class="tb">所属分类: {{ productInfoList.category }}</div>
        <div class="tb">联系人: {{ productInfoList.contacts }}</div>
        <div class="tb">联系电话: {{ productInfoList.mobile }}</div>
      </div>
    </div>
    <!-- 主要工作简历 -->
    <div class="business">
      <!-- 1 -->
      <div class="business_th w">
        <div class="box"></div>
        <div>收费模式</div>
      </div>
      <div class="business_tb w">
        {{ productInfoList.charging_mode }}
      </div>
      <!-- 2 -->
      <div class="business_th w">
        <div class="box"></div>
        <div>收费内容以及流程</div>
      </div>
      <div class="business_tb w">
        {{ productInfoList.service_content }}
      </div>
    </div>

    <app-footer />
  </div>
</template>

<script>
import Header from "../../components/Header/header.vue";
import Footer from "../../components/Footer/footer.vue";
import { product_info } from "../../api/policies.js";
export default {
  data() {
    return {
      productInfoList: {}, //机构详情
    };
  },
  created() {
    this.product_info();
  },
  methods: {
    async product_info() {
      let id = this.$route.params.id;
      console.log(id);
      const parameter = {
        id,
      };
      const { result } = await product_info(parameter);

      this.productInfoList = result;
      console.log(this.productInfoList);
    },
  },
  components: { "app-header": Header, "app-footer": Footer },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* 	overflow: hidden;
     */
}
.w {
  width: 1310px;
  margin: 0 auto;
}
.current_position {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.current_position_text {
  font-size: 14px;
  color: #838383;
}
.d8f {
  color: #0d8eff;
}
.particularsList {
  display: flex;
  height: 300px;
  margin-top: 25px;
  padding: 40px 50px;
  background: #cce7ff;
}
.particularsList .left {
  object-fit: cover;
  width: 350px;
  height: 220px;
  background-color: #6cf;
  border: 1px solid #e8e8e8;
  margin-right: 50px;
}
.particularsList .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.particularsList .right .th {
  font-size: 22px;
  font-weight: 700;
  text-align: left;
  color: #333333;
  padding-bottom: 5px;
}
.particularsList .right .tb {
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #595959;
  margin-top: 20px;
  line-height: 18px;
}
.particularsList .right .tb span {
  padding-left: 90px;
}
.business {
  margin-top: 30px;
  margin-top: 30px;
  min-height: 317px;
  margin-bottom: 30px;
  word-break: break-word;
}
.business .business_th {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #333333;
}
.business .business_th .box {
  width: 6px;
  height: 16px;
  background-color: #0d8eff;
  margin-right: 10px;
}
.business .business_tb {
  margin-top: 30px;
  padding-bottom: 60px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #595959;
}
</style>
